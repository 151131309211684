<template>
  <div>
    <v-simple-table height="auto" fixed-header>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-uppercase">
              ID Projet Planning
            </th>
            <th class="text-center text-uppercase">
              Logo
            </th>
            <th class="text-center text-uppercase">
              Nom de l'application
            </th>
            <th class="text-center text-uppercase">
              URL de l'application
            </th>
            <th class="text-center text-uppercase">
              URL par défaut
            </th>
            <th class="text-center text-uppercase">
              URL de LastMile
            </th>
            <th class="text-center text-uppercase">
              Email de l'admin
            </th>
            <th class="text-center text-uppercase">
              Statut SSL
            </th>
            <th class="text-center text-uppercase">
              Statut notification email
            </th>
            <th class="text-center text-uppercase">
              Statut notification agenda
            </th>
            <th class="text-center text-uppercase">
              Statut facture impayée
            </th>
            <th class="text-center text-uppercase">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in planningProjects" :key="item.planningProject">
            <td>{{ item.idApplicationName }}</td>
            <td class="text-center">
              <v-avatar size="40px">
                <img
                  :src="`${item.logo ? item.logo : require('@/assets/images/logos/default-planning-logo.jpg')}`"
                  alt=""
                />
              </v-avatar>
            </td>
            <td class="text-center">
              {{ item.applicationName }}
            </td>
            <td class="text-center">
              <a :href="item.appURL" target="_blank">{{ item.appURL }}</a>
            </td>
            <td class="text-center">
              <a :href="item.relativeURL" target="_blank">{{ item.relativeURL }}</a>
            </td>
            <td class="text-center">
              <a :href="item.lastMileUrl" target="_blank">{{ item.lastMileUrl }}</a>
            </td>
            <td class="text-center">
              {{ item.email }}
            </td>
            <td class="text-center">
              {{ item.SSLStatus }}
            </td>
            <td class="text-center">
              {{ item.flagEmail }}
            </td>
            <td class="text-center">
              {{ item.flagTaskAgenda }}
            </td>
            <td class="text-center">
              {{ item.flagAlertInvoice }}
            </td>
            <td class="text-center">
              <router-link :to="`/planning-project/${item.idApplicationName}`">
                <v-icon size="15">
                  {{ icons.mdiPencilOutline }}
                </v-icon>
              </router-link>
              &nbsp;
              <a href="#" @click="showDeleteDialog(item.idApplicationName)">
                <v-icon size="15">
                  {{ icons.mdiTrashCan }}
                </v-icon>
              </a>
            </td>
          </tr>
        </tbody>
        <p class="text-center">{{ message }}</p>
      </template>
    </v-simple-table>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title>Suppression</v-card-title>
        <v-card-text
          >êtes-vous sûr de vouloir supprimer le projet planning : `{{ itemToDelete }}`?
          <br />
          <v-alert
            style="margin-top:20px;margin-bottom:0px"
            color="error"
            v-show="message"
            dense
            dismissible
            outlined
            prominent
            type="error"
            >{{ message }}</v-alert
          >
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="dialogDelete = false">Annuler</v-btn>
          <v-btn color="primary" text @click="deletePlanningProject(itemToDelete)">Supprimer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mdiPencilOutline, mdiTrashCan } from '@mdi/js'
import PlanningProjectAPI from '../../api/PlanningProjectAPI'

export default {
  methods: {
    retrievePlanningProjects() {
      PlanningProjectAPI.getPlanningProjects()
        .then(response => {
          this.planningProjects = response.data.results
          console.log(this.planningProjects)
        })
        .catch(e => {
          this.message = e
        })
    },
    deletePlanningProject(idApplicationName) {
      PlanningProjectAPI.deletePlanningProjects(idApplicationName)
        .then(response => {
          this.retrievePlanningProjects()
          this.dialogDelete = !this.dialogDelete
        })
        .catch(e => {
          console.log(e)
          this.message = (e && e.message) || 'Erreur inconnu, veuillez réessayer ultérieurement'
        })
    },
    showDeleteDialog(item) {
      this.itemToDelete = item
      this.dialogDelete = !this.dialogDelete
    },
  },
  data() {
    return {
      planningProjects: [],
      message: '',
      icons: {
        mdiPencilOutline,
        mdiTrashCan,
      },
      dialogDelete: false,
      itemToDelete: {},
    }
  },
  created() {
    this.retrievePlanningProjects()
  },
}
</script>
